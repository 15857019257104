import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function SendGiftModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  // const [show, setShow] = useState(true);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', message);
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('idToCall', props.idToCall);
    formData.append('memberId', props.memberId);
    formData.append('calltype', props.calltype);
    formData.append('roomRowId', props.roomRowId);
    formData.append('roomId', props.roomId);
    formData.append('caller', props.caller);

    // console.log("submit report user", formData);
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    try {
      const response = await fetch(webUrl + "/reportUser.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");
          setNotification('Form submitted successfully');
          setShowAlert(true);
          props.onHide();
        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    }

  }


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };


  const giftpay = async (v) => {
    console.log(v);

    const formData = new FormData();
    formData.append('gift_value', v);
    formData.append('memberid', props.memberid);
    formData.append('sendto', props.sendto);
    formData.append('type', "gift-pay");

    // console.log("submit report user", formData);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetch(webUrl + "/sendGift.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");

          // if (value == "submit-shipping") {

          // let get_shippingfee = result.shippingfee;
          // if (get_shippingfee == "on") {
          //   window.open(result.paymentUrl, '_blank');
          // }

          setShowWaiting(true);
          window.open(result.paymentUrl, '_blank');

          check_for_gift_payment_status(result.memberid, result.sendto, result.giftrow_id);

          // shippingDetails[0].classList.remove("show");
          // prodgrp[0].remove();
          // submitChoice[0].remove();
          // received[0].remove();
          // proddetgrp[0].remove();
          // rewardgrp[0].remove();
          // shippingfee[0].classList.add("show");


          //show congratulations
          // alert("congratulations");
          // confirmRedeemProd[0].classList.remove("show");
          // congrats[0].classList.add("show");
          // setNotification('Form submitted successfully');
          // setShowAlert(true);

          // }

          // else if (value == "submit") {
          // setNotification('Form submitted successfully');
          // setShowAlert(true);
          // props.onHide();


          // }

        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    }
    // window.open(result.paymentUrl, '_blank');


  };




  let timerOn = true;
  const check_for_gift_payment_status = async (memberid, sendto, giftrow_id) => {

    // let shippingfee = document.querySelectorAll('.shippingfee');
    // let congrats = document.querySelectorAll('.congrats');

    // timerOn = true;
    console.log('Function executed after 2 seconds', memberid, sendto, giftrow_id);

    const formData = new FormData();
    formData.append('giftrow_id', giftrow_id);
    formData.append('memberid', memberid);
    formData.append('sendto', sendto);
    formData.append('type', 'gift-payment-status');
    try {
      const response = await fetch(webUrl + "/checkPaymentStatus.php", {
        method: 'POST',
        body: formData,
      });
      // setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          if (result.status == 'paid') {
            console.log("payment success");
            timerOn = false;
            setShowWaiting(false);
            setShowCongrats(true);
            // setNotification('Payment received ! Gift sent successfully');
            // setShowAlert(true);
            // props.onHide();
            // congrats[0].classList.add("show");
            // shippingfee[0].classList.remove("show");

          } else {
            console.log("waiting for payment");
          }
        } else {
          console.log("error");
          setNotification('Error encountered while checking payment status');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
      // Handle error
    }

    if (timerOn == true) {
      setTimeout(() => check_for_gift_payment_status(memberid, sendto, giftrow_id), 5000);
    }

  }


  return (
    <>
      <Modal
        {...props}
        size="lg" className='sendgift-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h3 className='tit'>SEND A GIFT</h3>
          <h5 className='subtit'>To This User</h5>
          from:           {props.memberid}          to:           {props.sendto}

          <div className='spacer'></div>
          <h5 className='subtit'>SEND</h5>
          <div className='spacer'></div>
          <div className='gifticon'>
            <img src="images/gift.svg" />
            <div className='tag'>GIFTBOX</div>
          </div>
          <div className='spacer'></div>
          <Button className='gift-pay-btn' type="button" onClick={() => giftpay("2.99")} >Send a gift $2.99</Button>

          <p className='note'>If the opposing user disconnects while your sending a gift, the gift will still send to them.</p>

          <h4 className='subtit'>NO REFUND POLICY</h4>

          {showWaiting == true && <div className='gift-pay-waiting'>
            <div class='wrap'>
              {/* <span className='isBtn close-giftpay-waiting' data-value='close-giftpay-waiting'>Close</span> */}
              <h4 className='tit'>Gift Payment</h4>
              <h5 className='subtit'>Waiting for confirmation from payment gateway</h5>
            </div>
          </div>}

          {showCongrats == true && <div className='gift-pay-success'>
            <div class='wrap'>
              {/* <span className='isBtn close-giftpay-success' data-value='close-giftpay-success'>Close</span> */}
              <h4 className='tit'>Gift Payment Successful</h4>
              <h5 className='subtit'>Your payment was successful and gift was successfully sent to user</h5>
            </div>
          </div>}


          {/* <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} value={message} onChange={handleMessageChange} />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Screenshot (optional)</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button type="submit">Send</Button>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default SendGiftModal;