import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import UserInfoModal from "./UserInfoModal";
import UserPinTopicModal from "./UserPinTopicModal";
// import { Button } from "bootstrap";
import Button from 'react-bootstrap/Button';

function UserSelectedTopic(props) {

  const [minutes, setMinutes] = useState(null);
  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showtopic, setShowtopic] = useState(true);
  const [tagline, setTagline] = useState("Belives");


  // Modal
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "user-selected-topic");
    formData.append('me', props.me);
    formData.append('memberid', props.memberId);
    formData.append('idtocall', props.idToCall);
    formData.append('roomid', props.roomId);
    formData.append('caller', props.caller);



    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        // console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          // console.log("Result Data:", result);
          if (result.success) {
            // console.log("success");
            // console.log(result);
            // console.log(result.topic_details.name);
            // console.log(result.topic_details.gender);
            // setMinutes(result.data.minutes);            
            // if(result.data.mlinfo.milestone_popup != ""){ 
            //   setMilestone(result.data.mlinfo.milestone_popup); 
            // setHtml(result.html);
            // let gender = result.topic_details.gender;
            // let topic = result.topic_details.name;

            if (result.topic_details.name != undefined) {
              setHtml(result.topic_details.name);
            }

            if (result.topic_details.tagline != undefined) {
              setTagline(result.topic_details.tagline);
            }
            //   setModalShow(true);  
            // }             
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }); // Empty dependency array means this effect runs only once on component mount

  function handleClick() {
    //   console.log("Button clicked!");
    // setModalShow(true);
    // setIsLoaded(true);

    const formData = new FormData();
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('memberId', props.memberId);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
            if (result.topics != "") {
              // setMilestone(result.data.mlinfo.milestone_popup);
              setHtml(result.html);
              console.log(result.topics);
              setOptions(result.topics);
              setModalShow(true);
              setIsLoaded(true);
            }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }

  function hideTopic() {
    if (showtopic) {
      setShowtopic(false);
    } else {
      setShowtopic(true);
    }
  }

  return (
    <div>
      {/* <span className='' onClick={() => handleClick()}><img src="images/topic.png" /></span> */}
      {/* {props.me}
      <br/>
      {props.idToCall}
      <br/> */}
      {html != "" ? <div className={'user-believes' + (showtopic == true ? " show " : " hide ") + ''}>
        <span className="micimg"><img src="images/topic.png" /></span>
        <h3>{tagline}</h3>
        <div className='topic-msg'>{html}</div>
        <span className="topic-hide" onClick={() => hideTopic()}><img className="topic-hide-img" src="images/topic-hide.png" /></span></div> : null}
      {/* { isLoaded ? <UserPinTopicModal show={modalShow} onHide={() => setModalShow(false)} options={options} type={"load-topics"} memberid={props.memberId} html={html} /> : null } */}
    </div>
  );
}

export default UserSelectedTopic;