import React, { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function RedeemProdPointModal(props) {

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [popup, setPopup] = useState(false);

  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState(null);

  // Modal
  const [modalShow, setModalShow] = React.useState(false);
  const [updateDoc, setUpdateDoc] = useState(0);

  const handleModalClose = () => {
    // props.onHide();
    // setFile(null);
    // setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  // useEffect(() => {

  //   // if (props.cpaType == "adpoints") {
  //   // setTitle("Your User ID is: " + props.memberid);
  //   // setUrl("https://form.jotform.com/242126063331141?user_id=" + props.memberid);
  //   // setShowIframe(true);
  //   // }
  //   // else {
  //   //   setTitle("");
  //   //   setUrl("");
  //   //   setShowIframe(false);
  //   // }


  // });

  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "redeem");
    formData.append('redeem_type', props.redeem_type);
    formData.append('memberId', props.memberid);
    // formData.append('me', props.me);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/redeemProductPoints.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("****************Data --:**********", response);
        console.log("****************Data --:**********", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log("**********", result, "**********");
            setHtml(result.html);

            // if(result.data.mlinfo.milestone_popup != ""){ 
            // setMilestone(result.data.mlinfo.milestone_popup); 
            // setModalShow(true);  
            // }            
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);   
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();


    const handleButtonClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      console.log('Clicked:', event.currentTarget.innerText, value);
      let catgrp = document.querySelectorAll('.categories-group');
      let prodgrp = document.querySelectorAll('.products-group');
      let confirmSpintowin = document.querySelectorAll('.confirm-spintowin');
      let progressSpintowin = document.querySelectorAll('.progress-spintowin');
      let allrewardChoices = document.querySelectorAll('.choose-reward');
      let allredeem = document.querySelectorAll('.redeem-product');
      let confirmRedeemProd = document.querySelectorAll('.confirm-redeemprod');
      let shippingDetails = document.querySelectorAll('.shipping-details.newpp');
      let submitChoice = document.querySelectorAll('.submit-choice');

      if (value == "show-products") {
        const cat = event.target.getAttribute('data-category');
        prodgrp[0].classList.remove("show");
        let catprodgrp = document.querySelectorAll('.products-group[data-category="' + cat + '"]');
        catprodgrp[0].classList.add("show");
        catgrp[0].classList.remove("show");
        // received[0].classList.remove("show");
        // showprodbtn[0].classList.remove("show");
      }

      if (value == "close-products") {
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });
        catgrp[0].classList.add("show");
      }

      if (value == "close-categories") {
        catgrp[0].classList.remove("show");
        props.onHide();
      }

      if (value == "open-product") {
        const prodid = event.target.getAttribute('data-prodid');
        // console.log('prodid:', prodid, '.product-detail-'+prodid+'');
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });
        // load product details of this product in .proddetgrp
        load_product_details(prodid, "open-for-prodpoint");
      }



      if (value == "prod-color") {
        const prodid = event.target.getAttribute('data-prodid');
        const color = event.target.getAttribute('data-color');
        let choose = document.querySelectorAll('.prod-color-' + prodid + '[data-color="' + color + '"]');
        let all = document.querySelectorAll('.prod-color-' + prodid + '');
        console.log(prodid, '.prod-color-' + prodid + '');

        all.forEach((red) => {
          red.classList.remove('active');
        });
        choose[0].classList.add("active");
      }

      if (value == "prod-size") {
        const prodid = event.target.getAttribute('data-prodid');
        const size = event.target.getAttribute('data-size');
        let choose = document.querySelectorAll('.prod-size-' + prodid + '[data-size="' + size + '"]');
        let all = document.querySelectorAll('.prod-size-' + prodid + '');
        console.log(prodid, '.prod-size-' + prodid + '');

        all.forEach((red) => {
          red.classList.remove('active');
        });
        choose[0].classList.add("active");
      }

      if (value == "close-product-detail") {
        const prodid = event.target.getAttribute('data-prodid');
        const cat = event.target.getAttribute('data-category');
        let proddet = document.querySelectorAll('.product-detail-' + prodid + '');
        proddet[0].classList.remove("show");
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });
        let catprodgrp = document.querySelectorAll('.products-group[data-category="' + cat + '"]');
        catprodgrp[0].classList.add("show");
      }


      if (value == "redeem-product") {
        const prodid = event.target.getAttribute('data-prodid');
        let prodredeem = document.querySelectorAll('.redeem-product-' + prodid + '');

        allrewardChoices.forEach((red) => {
          red.classList.remove('active');
        });

        allredeem.forEach((red) => {
          red.classList.remove('active');
        });

        prodredeem[0].classList.add("active");

        confirmRedeemProd[0].classList.add("show");

        // submitChoice[0].classList.add("show");

      }

      if (value == "yes-redeem") {
        confirmRedeemProd[0].classList.remove("show");
        shippingDetails[0].classList.add("show");
      }
      if (value == "no-redeem") {
        confirmRedeemProd[0].classList.remove("show");
      }

      if (value == "close-shipping-details") {
        shippingDetails[0].classList.remove("show");
      }


      if (value == "submit" || value == "submit-shipping") {

        let reward = document.querySelectorAll('.choose-reward.active');
        let product = document.querySelectorAll('.redeem-product.active');
        let color = document.querySelectorAll('.prod-color.active');
        let size = document.querySelectorAll('.prod-size.active');

        let firstname = document.querySelector('.newpp #firstname').value;
        let lastname = document.querySelector('.newpp #lastname').value;
        let country = document.querySelector('.newpp #country').value;
        let mobile = document.querySelector('.newpp #mobile').value;
        let address = document.querySelector('.newpp #address').value;
        let state = document.querySelector('.newpp #state').value;
        let zip = document.querySelector('.newpp #zip').value;
        let usernotes = document.querySelector('.newpp #usernotes').value;
        let address_id = document.querySelector('.newpp input[name="user_adrs"]:checked').value;

        let reward_val = "";
        if (reward.length > 0) {
          reward_val = reward[0].getAttribute('data-rewardid');
        }

        let product_val = "";
        let color_val = "";
        let size_val = "";
        if (product.length > 0) {
          product_val = product[0].getAttribute('data-prodid');
          if (color.length > 0) {
            color_val = color[0].getAttribute('data-color');
          }

          if (size.length > 0) {
            size_val = size[0].getAttribute('data-size');
          }

        }

        console.log("submit ", "reward: ", reward_val, "product: ", product_val, color_val, size_val);

        const formData = new FormData();
        formData.append('reward', reward_val);
        formData.append('product', product_val);
        formData.append('color', color_val);
        formData.append('size', size_val);
        formData.append('milestone', "");
        formData.append('type', "product-point-redeem");
        formData.append('memberId', props.memberid);
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('country', country);
        formData.append('mobile', mobile);
        formData.append('address', address);
        formData.append('state', state);
        formData.append('zip', zip);
        formData.append('usernotes', usernotes);
        formData.append('address_id', address_id);
        formData.append('redeem_type', props.redeem_type);

        // console.log("submit report user", formData);
        for (const [key, value] of formData.entries()) {
          console.log(key, value);
        }


        try {
          const response = await fetch(webUrl + "/userStats.php", {
            method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
            body: formData,
            // headers: {
            // 	'Content-Type': 'application/json',
            // },
            // body: JSON.stringify(requestData),
          });
          setLoading(false);
          if (response.ok) {
            const result = await response.json();
            console.log("Data:", result);
            if (result.success) {
              console.log("success");

              if (result.address == "taken") {
                console.log("address is taken");
                // alert("address taken");
                document.getElementById('address_taken_msg').classList.add("show");
              }

              //
              else {
                setNotification('Form submitted successfully');
                setShowAlert(true);
                props.onHideAll();
              }

            } else {
              console.log("error");
              setNotification('Error encountered while submitting');
              setShowAlert(true);
            }
          } else {
            console.log(response.statusText);
          }
        } catch (error) {
          console.log(error.message);
          console.error('Error uploading file:', error);
          // Handle error
        }
 

      }

      // if (value == "spintowin-product") {
      //   confirmSpintowin[0].classList.add("show");
      // }
      // if (value == "yes-spintowin") {
      //   progressSpintowin[0].classList.add("show");
      //   let options = event.currentTarget.getAttribute('data-opt');
      //   setTimeout(() => spinComplete(options), 2500);
      // }

      // if (value == "no-spintowin") {
      //   confirmSpintowin[0].classList.remove("show");
      // }

    };


    const handleRadioClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      const type = event.currentTarget.getAttribute('data-type');
      console.log('Clicked:', event.currentTarget.innerText, type, value);

      if (type == "user_adrs") {
        let adr_form = document.querySelectorAll('.new-address-form.newpp');
        if (value == "new") {
          adr_form[0].classList.remove("hide");
          adr_form[0].classList.add("show");
        } else {
          adr_form[0].classList.remove("show");
          adr_form[0].classList.add("hide");
        }
      }
    };

    const buttons = document.querySelectorAll('.isBtnpp'); // Adjust selector according to your HTML structure
    buttons.forEach((button) => {
      button.addEventListener('click', handleButtonClick);
    });


    const radios = document.querySelectorAll('.isRadiopp');
    radios.forEach((radio) => {
      radio.addEventListener('change', handleRadioClick);
    });
    // Cleanup function to remove event listeners when component unmounts
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', handleButtonClick);
      });

      radios.forEach((radio) => {
        radio.removeEventListener('change', handleRadioClick);
      });

      clearTimeout();
    };

  }); // Empty dependency array means this effect runs only once on component mount



  const spinComplete = async (options) => {
    console.log(options, "spincomplete");
    // call_spintowin(options);
  };

  const load_product_details = async (prodid, loadtype) => {

    const formData = new FormData();
    formData.append('prodid', prodid);
    formData.append('type', 'get-product-details');
    formData.append('loadtype', loadtype);
    formData.append('memberId', props.memberid);

    let proddetgrp = document.querySelectorAll('.product-detail-group');
    let allproddet = document.querySelectorAll('.product-detail');

    proddetgrp[0].innerHTML = "<br><br><br>Loading...<br><br><br><br>";

    try {
      const response = await fetch(webUrl + "/userStats.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
      });
      // setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {

          proddetgrp[0].innerHTML = result.product_details;
          let proddet = document.querySelectorAll('.product-detail-' + prodid + '');
          allproddet.forEach((det) => {
            det.classList.remove('show');
          });
          proddet[0].classList.add("show");
          let newRandomNumber = Math.floor(Math.random() * 1000); // Generate a new random number
          setUpdateDoc(newRandomNumber);

          // if (result.status == 'paid') {
          //   console.log("payment success");
          //   // timerOn = false;
          //   // congrats[0].classList.add("show");
          //   // shippingfee[0].classList.remove("show");
          // } else {
          //   console.log("waiting for payment");
          // }
        } else {
          console.log("error");
          setNotification('Error encountered while fetching product details');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
      // Handle error
    }
  };


  // const popupAction = async (action) => {
  //   // console.log(action);
  //   if (action == "yes") {
  //     await submit_gcform_filled("");
  //   }
  //   else if (action == "no") {
  //     setPopup(false);
  //   }
  // };

  return (
    <>
      <Modal         {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="unlock-rewards-modal"
      // backdrop="static"
      >
        <Modal.Header>
          {/* <Button onClick={() => setPopup(true)} variant="">X Close</Button> */}
        </Modal.Header>
        <Modal.Body >
          {/* <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} /> */}


          {/* <div className='-filled'>
            <div className='text-center'> */}
          {/* <Button onClick={() => popupAction("yes")} variant="success">Yes</Button> &nbsp; &nbsp; <Button onClick={() => popupAction("no")} variant="danger">No</Button> */}
          {/* </div>
          </div> */}
          <div dangerouslySetInnerHTML={{ __html: html }} />

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default RedeemProdPointModal;