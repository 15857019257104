const interests = [
  { value: "friendship", label: "Friendship" },
  { value: "study_buddy", label: "Study Buddy" },
  { value: "find_bfgf", label: "Find BF / GF" },
  { value: "lcbtq", label: "LCBTQ+" },
  { value: "trans", label: "Trans" },
  { value: "hookups", label: "Hookups" },
  { value: "gaming_buddy", label: "Gaming Buddy" },
  { value: "twitch", label: "Twitch" },
  { value: "gaming", label: "Gaming" },
  { value: "youtube", label: "youTube" },
  { value: "tiktok", label: "TikTok" },
  { value: "cooking", label: "Cooking" },
  { value: "fitness", label: "Fitness" },
  { value: "gardening", label: "Gardening" },
  { value: "mental_health", label: "Mental Health" },
  { value: "career", label: "Career" },
  { value: "religious", label: "Religious" },
  { value: "music", label: "Music" },
  { value: "sports", label: "Sports" },
  { value: "health", label: "Health" },
  { value: "fashion", label: "Fashion" },
  { value: "food", label: "Food" },
  { value: "vegan", label: "Vegan" },
  { value: "pet_lovers", label: "Pet Lovers" },
  { value: "tech", label: "Tech" },
  { value: "language_exchange", label: "Language exchange" },
  { value: "business", label: "Business" }
];

export default interests;