// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField"
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Peer from "simple-peer"
import io from "socket.io-client"
import "./App.css"
import "./Vip.css"
// import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import ReportUserModal from "./ReportUserModal";
import UserStats from "./UserStats";
import { baseUrl, webUrl } from './variables';
import ProfileModal from "./ProfileModal"
import PromoForm from "./PromoForm"
import PromoModal from "./PromoModal"
import PageLoader from "./PageLoader"
import UserTopic from "./UserTopic"
import UserSelectedTopic from "./UserSelectedTopic"
import ReportPromoModal from "./ReportPromoModal"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VipModal from "./VipModal"
import VipSettingsModal from "./VipSettingsModal"
import UserPinnedSocialApps from "./UserPinnedSocialApps"
import SendGift from "./SendGift"
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2
	}
};


// const socket = io.connect('http://localhost:5000') // FOR OFFLINE  
const socket = io.connect('https://www.earnchatpromo.io')  // FOR ONLINE 

function App() {

	// const location = useLocation();
	// const queryParams = new URLSearchParams(location.search);
	// const roomId = ""; // queryParams.get('r');
	const queryParams = new URLSearchParams(window.location.search);
	const roomId = queryParams.get('r');
	const memberId = queryParams.get('m');
	const type = queryParams.get('t');
	const roomRowId = queryParams.get('rid');
	const mode = queryParams.get('mode');
	const ban = queryParams.get('ban');
	const autoStart = true;
	const fetchPromo = true;
	// console.log(roomId);
	// console.log(memberId);
	// console.log(type);
	// console.log(roomRowId);


	/*
	// const baseUrl = 'http://154.56.60.141:3000';
	// const baseUrl = 'https://www.earnchatpromo.io:3000';
	*/

	const [me, setMe] = useState("")
	const [stream, setStream] = useState()
	const [receivingCall, setReceivingCall] = useState(false)
	const [caller, setCaller] = useState("")
	const [callerSignal, setCallerSignal] = useState()
	const [callAccepted, setCallAccepted] = useState(false)
	const [callEnded, setCallEnded] = useState(false)
	const [name, setName] = useState("")
	const myVideo = useRef({})
	const userVideo = useRef()
	const connectionRef = useRef()
	const externalLink = baseUrl + '?r=' + me;
	const [idToCall, setIdToCall] = useState("")

	// Modal
	const [modalShow, setModalShow] = React.useState(false);
	const [reportPromoModalShow, setReportPromoModalShow] = React.useState(false);

	const [btnbarShow, setBtnbarShow] = React.useState(false);
	const [modalProfileShow, setModalProfileShow] = React.useState(false);
	const [modalPromoShow, setModalPromoShow] = React.useState(false);
	// idToCall = externalLink;

	const [modalVipShow, setModalVipShow] = React.useState(false);
	const [modalVipSettingsShow, setModalVipSettingsShow] = React.useState(false);

	const [audioInputDevices, setAudioInputDevices] = useState([]);
	const [showAudioDevicesDropdown, setShowAudioDevicesDropdown] = useState(false);
	const [selectedDeviceId, setSelectedDeviceId] = useState('');
	let timerId = null;
	// const [banned, setBanned] = useState("");

	const [promohtml, setPromohtml] = useState("")
	const [fetchedPromo, setFetchedPromo] = useState(false);
	const [promopause, setPromopause] = useState(false);
	const promopauseRef = useRef(promopause);
	const [promoid, setPromoid] = useState("")

	const [loading, setLoading] = useState(true);
	let useEffectCounter = 0;
	const [uecount, setUecount] = useState(0)
	const [callStarted, setCallStarted] = useState(0)
	const [minutesCap, setMinutesCap] = useState(false)


	useEffect(() => {
		setUecount(uecount + 1);
		console.log("useffect", uecount);
		if (mode == "on" && (ban == null || ban == "")) {


			navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream) => {
				setStream(stream)
				setLoading(false);
				console.log("setstream");
				myVideo.current.srcObject = stream;

				if (autoStart == true) {

					// fetch promo
					// if (fetchPromo == true) {
					// 	fetchDisplayPromo();
					// } else {

					autoStartCall();
					// }

				}

			})
		}

		else if (mode.startsWith("promo-")) {
			if (uecount == 0) {
				let fetch_promoid = mode.slice(6);
				setPromoid(fetch_promoid);
				console.log("========== mode is promo, so call fetchDisplayPromo =============", fetch_promoid);
				fetchDisplayPromo(fetch_promoid);
			}
		}

		else if (mode.startsWith("ml_unlocked-")) {
			if (uecount == 0) {
				let fetch_mlid = mode.slice(12);
				setLoading(false);
				console.log("========== mode is milestone unlocked, so dont start video call =============", fetch_mlid);
			}
		}

		else {
			setLoading(false);
			console.log("User Banned");
		}

		socket.on("me", (id) => {
			if (mode == "on") {
				console.log("Me:", id);
				setMe(id)
				console.log("set me");
				if (memberId != "" && type == "new") {

					manageRooms(id);
				}
				else if (memberId != "" && type == "join") {
					manageRooms(id);
				}

				if (roomId != null && roomId != "") {
					console.log("roomid is not empty: call setIdToCall");
					setIdToCall(roomId);
					console.log("set idtocall");
				}
			}
		})


		socket.on("callUser", (data) => {
			if (mode == "on") {
				console.log('on calluser');
				setReceivingCall(true)
				setCaller(data.from)
				setName(data.name)
				setCallerSignal(data.signal)
				console.log('on calluser end');
			}
		})

		// socket.on("callEnded", (id) => {
		// 	console.log("callEnded: " + id);
		// })

		socket.on("userDisconnected", (id) => {
			if (mode == "on") {
				console.log("userDisconnected: " + id);
				setCallEnded(true)
				setCallAccepted(false)
				nextCall();
			}
		})

		socket.on("info", (info) => {
			console.log("info: " + info);
		})

		// return () => clearTimeout(timerId);

		// promopauseRef.current = promopause;

		// page loader
		// 

		const buttons = document.querySelectorAll('.isBtnprm'); // Adjust selector according to your HTML structure
		buttons.forEach((button2) => {
			console.log("button2", button2);
			button2.addEventListener('click', handleButtonClick);
		});

		// Cleanup function to remove event listeners when component unmounts
		return () => {
			buttons.forEach((button) => {
				button.removeEventListener('click', handleButtonClick);
			});

			clearTimeout();
		};

		// initializeVideoCall();
	}, [idToCall, receivingCall, callEnded, promohtml])

	// Add event listeners to the buttons after HTML content is set
	const handleButtonClick = async (event) => {
		const value = event.target.getAttribute('data-value');
		console.log('Clicked:', event.target.innerText, value);
		if (value == "promo-url") {
			const url = event.target.getAttribute('data-promo-url');
			const id = event.target.getAttribute('data-id');
			update_promo_stat(id, "url_opened", url);
			// window.open(url, "_blank");
		}
	};

	const fetchDisplayPromo = async (prmid) => {
		console.log("fetchDisplayPromo:", prmid);
		const formData = new FormData();
		formData.append('type', "fetch-promo-by-id");
		formData.append('memberid', memberId);
		formData.append('promoid', prmid);
		try {
			const response = await fetch(webUrl + "/promos.php", {
				method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
				body: formData,
			});
			console.log("fetchData Data:", response.body);

			if (response.ok) {
				const result = await response.json();
				console.log("fetchDisplayPromo Data:", result);
				if (result.success) {
					// console.log(result.html);

					// display promo
					if (result.html != "") {
						// setFetchedPromo(true);
						setPromohtml(result.html);
						setLoading(false);

						// console.log(promopause);

						timerId = setTimeout(() => {
							// remove promo if any
							console.log("timeout promopause: ", promopause);
							console.log("time promopauseRef.current: ", promopauseRef.current);
							if (promopauseRef.current == false) {
								setPromohtml("");
								setPromopause(false);
								nextCall();
							}
						}, 8000);
					}
					//
					else {
						nextCall();
					}
				}
				//
				else {
					nextCall();
				}
			}
			//
			else {
				console.log(response.statusText);
				nextCall();
			}
		} catch (error) {
			console.log(error.message);
			nextCall();
		}

	}

	const manageRooms = async (id) => {
		console.log("manageRooms");
		try {
			let requestData = {};
			if (type == "new") {
				requestData = {
					memberId: memberId,
					type: type,
					roomId: id,
				};
			}
			else if (type == "join") {
				requestData = {
					memberId: memberId,
					type: type,
					socketId: id,
					roomRowId: roomRowId
				};
			}
			const response = await fetch(webUrl + "/manageRooms.php", {
				method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestData),
			});
			// console.log("Response:", response);
			// const response = await fetch(webUrl +"/manageRooms.php?u="+memberId+"&s=new"+"&id="+me);
			if (response.ok) {
				const result = await response.json();
				console.log("======== manageRooms Data: ========", result);
				// callUser(idToCall);

			} else {
				console.log(response.statusText);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	const callUser = (id) => {
		console.log('click calluser');
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			console.log('peer on signal');
			socket.emit("callUser", {
				userToCall: id,
				signalData: data,
				from: me,
				name: name
			})
			console.log('socket emit calluser');
		})
		peer.on("stream", (stream) => {
			console.log('peer on stream');
			userVideo.current.srcObject = stream
		})
		socket.on("callAccepted", (data) => {
			console.log("to: ", data.to);
			setCallAccepted(true)
			peer.signal(data.signal)

			// console.log("------- CALL callAccepted ------------", (new Date().valueOf() / 1000));
			setCallStarted((new Date().valueOf() / 1000));

		})

		connectionRef.current = peer
	}

	const answerCall = () => {
		setCallAccepted(true)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("answerCall", { signal: data, to: caller })
		})
		peer.on("stream", (stream) => {
			userVideo.current.srcObject = stream
		})


		// console.log("------- CALL answerCall ------------",(new Date().valueOf() / 1000));
		setCallStarted((new Date().valueOf() / 1000));

		peer.signal(callerSignal)
		connectionRef.current = peer
	}

	const leaveCall = () => {
		let nexturl = baseUrl + '/url.html?id=' + memberId + "&mode=pause";
		console.log(nexturl);
		window.location = nexturl;

		/*
		setCallEnded(true)
		setCallAccepted(false)
		*/
		// socket.emit("disconnect", {});
		// if (connectionRef.current) {
		// 	connectionRef.current.destroy()
		// }
	}


	const minutesCapBtn = () => {
		nextCall();
	};


	const nextCallClicked = () => {

		let cappop = localStorage.getItem('mintuesCapPopup');
		// console.log("----mintuesCapPopup---",cappop);

		if (callStarted > 0 && !mode.startsWith("promo-") && (cappop == null || cappop == "")) {
			let timenow = (new Date().valueOf() / 1000);
			let diff = Math.round(timenow - callStarted);
			// console.log("check user time:", callStarted, timenow, diff);

			if (diff > 600) {
				// console.log("show popup of call minutes capped to 10 mins");
				setMinutesCap(true);
				localStorage.setItem('mintuesCapPopup', "shown-once");
			} else {
				nextCall();
			}

		} else {
			nextCall();
		}

	}

	const nextCall = () => {

		if (mode.startsWith("promo-")) {
			console.log("Promo View ended: ", promoid);
			update_promo_stat(promoid, "closed", "next");
		}
		else {
			nextCallRedirect();
		}
	}

	const nextCallRedirect = () => {
		let nexturl = baseUrl + '/url.html?id=' + memberId + "&mode=on";
		console.log(nexturl);
		window.location = nexturl;
		// window.location = webUrl + "/start.php";
	}


	const stopCall = () => {
		if (mode.startsWith("promo-")) {
			console.log("Promo View ended: ", promoid);
			update_promo_stat(promoid, "closed", "stop");
		}
		else {
			stopCallRedirect();
		}
	}

	const stopCallRedirect = () => {
		window.top.location = webUrl + "/index.php";
	}


	function autoStartCall() {
		// console.log("autoStartCall", "promopause:", promopause );
		// click call button automatically
		if (callAccepted && !callEnded) {
			console.log("111", idToCall);
		} else if (idToCall != null && idToCall != "") {
			console.log("222 callUser(idToCall) ", idToCall);
			const button = document.getElementById("callButtonEle");
			button.click();
		} else {
			console.log("333 ", idToCall);
		}


		// click answerbutton automatically
		if (receivingCall && !callAccepted) {
			console.log("444 answerCall ");
			setTimeout(() => {
				const ansbutton = document.getElementById("answerButtonEle");
				ansbutton.click();
				// answerButtonRef.current.click();
			}, 500);
			// clearTimeout(delay);
		} else {
			console.log("555 answerCall ");
		}

	}

	function pauseForPromo() {
		update_promo_stat(promoid, "paused", "");

	}

	const update_promo_stat = async (prmid, action, url) => {

		let pdisp = document.querySelectorAll('.promo-player-display');

		if (pdisp != undefined && pdisp.length > 0) {
			let statsid = pdisp[0].getAttribute('data-stats-id');

			pdisp[0].classList.remove('explicit');

			console.log(prmid, action, url, " statsid: ", statsid);

			// update stat and on success continue actions
			const formData = new FormData();

			formData.append('type', "promo-stats-update");
			formData.append('promoid', prmid);
			formData.append('statsid', statsid);
			formData.append('action', action);
			formData.append('memberid', memberId);
			console.log("", formData);
			for (const [key, value] of formData.entries()) {
				console.log(key, value);
			}

			try {
				const response = await fetch(webUrl + "/promos.php", {
					method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
					body: formData,
					// headers: {
					// 	'Content-Type': 'application/json',
					// },
					// body: JSON.stringify(requestData),
				});
				if (response.ok) {
					const result = await response.json();
					console.log("Data:", result);
					if (result.success) {
						console.log("success");
						////
						if (action == "paused") {
							setPromopause(true);
							promopauseRef.current = true;
						}
						else if (action == "url_opened") {
							window.open(url, "_blank");
						}
						else if (action == "closed" && url == "next") {
							nextCallRedirect();
						}
						else if (action == "closed" && url == "stop") {
							stopCallRedirect();
						}
						///

					} else {
						console.log("error");
					}
				} else {
					console.log(response.statusText);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else if (action == "closed" && url == "next") {
			nextCallRedirect();
		}
		else if (action == "closed" && url == "stop") {
			stopCallRedirect();
		}



	};

	const getUserMedia = async () => {
		try {
			// const audioInputDevices = await navigator.mediaDevices.enumerateDevices()
			// 	.then(devices => devices.filter(device => device.kind === 'audioinput'));

			const devices = await navigator.mediaDevices.enumerateDevices();
			const audioDevices = devices.filter(device => device.kind === 'audioinput');
			setAudioInputDevices(audioDevices);
			setShowAudioDevicesDropdown(true);

			if (audioDevices.length === 1) {
				setSelectedDeviceId(audioDevices[0].deviceId);
			}

		} catch (error) {
			console.error('Error accessing media devices:', error);
		}
	};


	const handleDeviceChange = async (deviceId) => {
		setSelectedDeviceId(deviceId);
		try {
			const constraints = {
				video: true, // Request video
				audio: { deviceId }, // Specify the selected audio device
			};

			const newStream = await navigator.mediaDevices.getUserMedia(constraints);
			setStream(newStream);
			myVideo.current.srcObject = newStream;
			setShowAudioDevicesDropdown(false);
			setSelectedDeviceId(deviceId);
		} catch (error) {
			console.error('Error accessing media devices:', error);
		}
	};



	return (
		<>

			{/* {(ban == null || ban == "") ? <div className="banned-wrapper"></div> : null} */}

			{/* <h1 style={{ textAlign: "center", color: '#fff' }}>roomid: {roomId}</h1> */}
			{(ban == null || ban == "") ? <div className="videocall-wrapper">
				<div className="video-container">
					<div className="video userVideoBox">
						<div className="userSelectedTopic">
							<UserSelectedTopic me={me} memberId={memberId} idToCall={idToCall} caller={caller} roomId={roomId} />
						</div>
						{mode == "pause" || mode.startsWith("ml_unlocked-") ? <div className="endedCall-wrap">
							<img src="images/loading.gif" className="endedcall-loader" />
							<div className="incontent">
								<img src="images/logo-new.png" className="logoimg" />
								<img src="images/holdingcash.gif" className="cashimg" />
								<img src="images/rewards.gif" className="rewardsimg" />
							</div>
						</div> : <div className="connecting-loader"><img src="images/loading.gif" /><div className="loader"></div><span className="loader-horizontal"><span className="bar"></span></span></div>}

						<div className={"myId call-actions " + (callAccepted && !callEnded ? "ongoing" : "")}>
							{/* <div className="socket-users-info">me: {me}  &nbsp; 	user: {idToCall} {caller}</div> */}
							<div className="call-button">
								{callAccepted && !callEnded
									? (
										autoStart === true
											? (null)
											: (<Button variant="contained" color="secondary" onClick={leaveCall}>End Call</Button>)
									)
									: idToCall != null && idToCall != ""
										? (<Button id="callButtonEle" color="" aria-label="call" onClick={() => callUser(idToCall)}>
											{autoStart == true ? "..." : "Join Call"}
										</Button>)
										: (<div>{autoStart === true ? (null) : (<div>Share Url: <br /><b>{externalLink}</b></div>)}
										</div>)}
							</div>
							<div className="autocall-buttons">
								{callAccepted && !callEnded
									? <Button variant="danger" className="next-call ongoing" onClick={nextCallClicked}><img src="images/next-icon.svg" /><div><img src="images/next-text.svg" /></div></Button>
									: <Button variant="danger" className="next-call" onClick={nextCallClicked}>Start</Button>}
								{callAccepted && !callEnded
									? <Button variant="danger" className="end-call" onClick={leaveCall}><img src="images/stop.png" /></Button>
									: <Button variant="" className="stop-call" onClick={stopCall}><img src="images/home.svg" /><div>Home</div></Button>}
								{/* {<Button variant="danger" className="stop-call" onClick={stopCall}>Stop</Button>} */}
							</div>

						</div>



						<div className="reportuser-button" >
							{callAccepted && !callEnded ? <Button onClick={() => setModalShow(true)}><img src="images/report.png" /></Button> :
								null}

							<SendGift me={me} memberId={memberId} idToCall={idToCall} caller={caller} roomId={roomId} />
							<UserPinnedSocialApps me={me} memberId={memberId} idToCall={idToCall} caller={caller} roomId={roomId} />

						</div>
						{callAccepted && !callEnded ?
							<video playsInline id="userVideo" ref={userVideo} autoPlay /> :
							null}
						<div className={promopause ? "promo-paused" : ""} dangerouslySetInnerHTML={{ __html: promohtml }} />
						<div>
							{promohtml != "" ?
								promopause == false ?
									<Button variant="info" className="pauseForPromo" onClick={pauseForPromo}>Pause to View Promo</Button>
									: <></>
								: <></>}

							{promohtml != "" ?
								<div variant="" className="reportPromo" onClick={() => setReportPromoModalShow(true)} >Report Promo</div>
								: <></>}

						</div>
					</div>
					<div className="video myVideoBox">
						<div className="myStats"><UserStats me={me} memberid={memberId} /></div>
						<div className="myTopic"><UserTopic me={me} memberid={memberId} /></div>
						<img src="images/myvideo-loader.gif" className="myvideo-loader" />
						{stream && <video className={promopause == true ? " hide " : ""} id="meVideo" playsInline muted ref={myVideo} autoPlay />}
						<div className="audio-wrap">
							<Button variant="success" className="audio-source" onClick={getUserMedia}><img src="images/microphone.png" /></Button>
							{showAudioDevicesDropdown && (<select id="audioDevices" onChange={(e) => handleDeviceChange(e.target.value)} value={selectedDeviceId}>
								{audioInputDevices.map(device => (
									<option key={device.deviceId} value={device.deviceId}>{device.label || `Microphone ${device.deviceId}`}</option>
								))}
							</select>)}
						</div>
					</div>
				</div>

				<div>
					{receivingCall && !callAccepted ? (
						<div className="caller">
							{/* <h1 >{name} is calling...</h1> */}
							<Button id="answerButtonEle" color="" onClick={answerCall}>
								{autoStart == true ? "Connecting..." : "Answer Call"}
							</Button>
						</div>
					) : null}
				</div>
			</div> : <div className="banned-wrapper">
				<div className="banned-container">
					<div className="userBox"><div className="incontent"><img src="images/logo.png" /></div></div>
					<div className="myBox">
						<div className="incontent">
							<h2>Banned User</h2>
							<img src="images/banned-user.png" />
							<div className="bantill">Banned till: <span>{ban}</span></div>
							<Button variant="primary" className="pay-to-unban">Pay to Unban</Button>
							<div className="msg">You no longer can connect with users.</div>
						</div>
					</div>
				</div>
			</div>}
			{loading && <PageLoader />}
			<div className="bottombar">


				<div className="btnbar-toggle"><Button variant="" className={"toggle-btn " + btnbarShow} onClick={() => setBtnbarShow(!btnbarShow)}><img src="images/bar-toggle.png" /></Button></div>

				{btnbarShow == true ? <Carousel responsive={responsive} arrows={false} >
					<div className="btnbar"><Button variant="" className="profile-btn" onClick={() => setModalProfileShow(true)}><img src="images/profile.svg" /><div>Profile</div></Button></div>
					{(ban == null || ban == "") ? <div className="btnbar"><Button variant="" className="promos-btn" onClick={() => setModalPromoShow(true)}><img src="images/promos.svg" /><div>Promos</div></Button></div> : null}
					<div className="btnbar"><Button variant="" className="redeem-btn"><img src="images/redeem.svg" /><div>Redeem</div></Button></div>
					<div className="btnbar"><Button variant="" className="vip-btn" onClick={() => setModalVipShow(true)}><img src="images/vip.gif" /><div>VIP</div></Button></div>
					<div className="btnbar"><Button variant="" className="earn-btn"><img src="images/earn.svg" /><div>Earn Fast</div></Button></div>
				</Carousel> : null}

				{/* <div className="btns"> */}
				{/* <Button variant="primary" className="profile-btn" onClick={() => setModalProfileShow(true)}>Profile</Button>
					<Button variant="primary" className="promos-btn" onClick={() => setModalPromoShow(true)}>Promos</Button>
					<Button variant="primary" className="vip-btn">VIP Access</Button> */}
				{/* {promopause ? "promopause true" : "promopause false"} */}
				{/* </div> */}
				{/* <br /> */}
				{/* idToCall: {idToCall} &nbsp; me: {me} &nbsp; roomId: {roomId} &nbsp; caller: {caller} &nbsp; memberId: {memberId}   */}
			</div>

			{minutesCap == true ?
				<div className="minutes-cap-popup">
					<h4 className="msg">When you <span className="highlight">reach 10 minutes</span> chatting with a user. You will stop earning minutes until you connect with someone new. You can still chat with that user but you won’t earn extra minutes. VIP gets 30 Min Cap!</h4>
					<Button variant="primary" className="minutes-cap-btn" onClick={() => minutesCapBtn()}>I Understand</Button>
					<br />
					<Button variant="primary" className="vip-btn">VIP Access</Button>
				</div> : null
			}

			{modalShow ? <ReportUserModal show={modalShow} onHide={() => setModalShow(false)} idToCall={idToCall} me={me} caller={caller} memberId={memberId} calltype={type} roomRowId={roomRowId} roomId={roomId} /> : null}
			{modalProfileShow ? <ProfileModal show={modalProfileShow} onHide={() => setModalProfileShow(false)} idToCall={idToCall} me={me} caller={caller} memberid={memberId} calltype={type} roomRowId={roomRowId} roomId={roomId} /> : null}
			{modalPromoShow ? <PromoModal show={modalPromoShow} onHide={() => setModalPromoShow(false)} memberid={memberId} me={me} ></PromoModal> : null}
			{reportPromoModalShow ? <ReportPromoModal show={reportPromoModalShow} onHide={() => setReportPromoModalShow(false)} idToCall={idToCall} me={me} caller={caller} memberId={memberId} calltype={type} roomRowId={roomRowId} roomId={roomId} /> : null}

			{modalVipShow ? <VipModal show={modalVipShow} onHide={() => setModalVipShow(false)} openVipSettings={() => setModalVipSettingsShow(true)} me={me} caller={caller} memberid={memberId} /> : null}
			{modalVipSettingsShow ? <VipSettingsModal show={modalVipSettingsShow} onHide={() => setModalVipSettingsShow(false)} me={me} caller={caller} memberid={memberId} /> : null}

		</>
	)
}

export default App