import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';
import PromoForm from './PromoForm';

function PromoModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [mypromos, setMypromos] = useState(null);
  const [templates, setTemplates] = useState(null);
  // const [show, setShow] = useState(true);
  const [showPart, setShowPart] = useState(1);
  const [formid, setFormid] = useState("");
  const [formtype, setFormtype] = useState("");
  const [isVip, setIsVip] = useState(false);

  const toggleParts = (p) => {
    console.log(p);

    if (p == 1) {
      setFormtype("");
      setFormid("");
    }

    setShowPart(p);
    // setShowPart(prevShowPart => !prevShowPart);
  };

  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };



  useEffect(() => {

    const formData = new FormData();

    formData.append('type', "list-promos");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/promos.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("fetchData Data:", response.body);

        if (response.ok) {
          const result = await response.json();
          console.log("Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            setMypromos(result.mypromos);
            setTemplates(result.mytemplates);

            if (result.vip == "yes") {
              setIsVip(true);
            }

          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (showPart) {
      fetchData();
    }

    // Add event listeners to the buttons after HTML content is set
    const handleButtonClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      const rowid = event.currentTarget.getAttribute('data-id');
      console.log('Clicked isBtnPromo:', value, rowid);
      // let myprofgrp = document.querySelectorAll('.user-profile .my-profile');
      // let profile_rewards = document.querySelectorAll('.user-profile .profile-rewards');

      if (value == "usetemplate" || value == "edit") {
        console.log("edit action");
        setFormtype(value);
        setFormid(rowid);
        toggleParts(2);
      }
      if (value == "status") {
        let t = event.currentTarget.getAttribute('data-type');
        console.log(t);
        if (t == "on") {
          event.currentTarget.setAttribute('data-type', 'off');
          promo_action(rowid, 'off', "promo-status");
        } else {
          event.currentTarget.setAttribute('data-type', 'on');
          promo_action(rowid, 'on', "promo-status");
        }
      }

      if (value == "delete") {
        const confirm = event.currentTarget.getAttribute('data-confirm');
        if (confirm == "false") {
          event.currentTarget.setAttribute('data-confirm', 'true');
          event.currentTarget.innerHTML = 'Confirm delete?';
        }
        if (confirm == "true") {
          console.log("delete:", rowid);
          promo_action(rowid, '', "promo-delete");
        }

      }

      // if (value == "close-profile") {
      //   props.onHide();
      // }

      // if (value == "close-profile-rewards") {
      //   myprofgrp[0].classList.add("show");
      //   profile_rewards[0].classList.remove("show");
      // }

      // if (value == "tracking-url") {
      //   const url = event.target.getAttribute('data-tracking-url');
      //   window.open(url, "_blank");
      // }

      // if (value == "edit-shipping") {
      //   const id = event.target.getAttribute('data-id');
      //   let form = document.querySelectorAll('.shipping-details[data-id="' + id + '"]');
      //   form[0].classList.add("show");
      //   // const url = event.target.getAttribute('data-tracking-url');
      //   // window.open(url,"_blank");
      // }

      // if (value == "submit-shipping-save") {
      //   console.log("submit-shipping-save");
      //   const id = event.target.getAttribute('data-id');
      //   await submit_shipping_edit(id);
      // }


    };

    const promo_action = async (id, status, type) => {
      console.log(id, status);

      // event.preventDefault();
      // setLoading(true);

      const formData = new FormData();
      formData.append('type', type);
      formData.append('id', id);
      formData.append('status', status);
      console.log("", formData);
      for (const [key, value] of formData.entries()) {
        console.log(key, value);
      }

      try {
        const response = await fetch(webUrl + "/promos.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
          // headers: {
          // 	'Content-Type': 'application/json',
          // },
          // body: JSON.stringify(requestData),
        });
        // setLoading(false);
        if (response.ok) {
          const result = await response.json();
          console.log("Data:", result);
          if (result.success) {
            console.log("success");

            if (type == "promo-delete") {
              console.log("promo deleted", id);
              let promo_row = document.querySelector('.promo-row-' + id);
              promo_row.remove();
            }
            // setNotification('Form submitted successfully');
            // setShowAlert(true);
            // props.onHide();
          } else {
            console.log("error");
            // setNotification('Error encountered while submitting');
            // setShowAlert(true);
          }
        } else {
          console.log(response.statusText);
        }
      } catch (error) {
        console.log(error.message);
        // console.error('Error uploading file:', error);
        // Handle error
      }
    }

    // const submit_shipping_edit = async (id) => {


    //   console.log(id);
    //   let firstname = document.querySelectorAll('.firstname-' + id + '')[0].value;
    //   let lastname = document.querySelectorAll('.lastname-' + id + '')[0].value;
    //   let country = document.querySelectorAll('.country-' + id + '')[0].value;
    //   let mobile = document.querySelectorAll('.mobile-' + id + '')[0].value;
    //   let address = document.querySelectorAll('.address-' + id + '')[0].value;
    //   let state = document.querySelectorAll('.state-' + id + '')[0].value;
    //   let zip = document.querySelectorAll('.zip-' + id + '')[0].value;
    //   let usernotes = document.querySelectorAll('.usernotes-' + id + '')[0].value;


    //   const shipData = new FormData();
    //   shipData.append('type', "shipping-edit");
    //   shipData.append('id', id);
    //   shipData.append('firstname', firstname);
    //   shipData.append('lastname', lastname);
    //   shipData.append('country', country);
    //   shipData.append('mobile', mobile);
    //   shipData.append('address', address);
    //   shipData.append('state', state);
    //   shipData.append('zip', zip);
    //   shipData.append('usernotes', usernotes);
    //   console.log(shipData);

    //   try {
    //     const response = await fetch(webUrl + "/userStats.php", {
    //       method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
    //       body: shipData,
    //       // headers: {
    //       // 	'Content-Type': 'application/json',
    //       // },
    //       // body: JSON.stringify(requestData),
    //     });
    //     setLoading(false);
    //     if (response.ok) {
    //       const result = await response.json();
    //       console.log("Data:", result);
    //       if (result.success) {
    //         console.log("success");

    //         let form = document.querySelectorAll('.shipping-details[data-id="' + id + '"]');
    //         form[0].classList.remove("show");
    //         setNotification('Details submitted successfully');
    //         setShowAlert(true);

    //         // props.onHide();


    //       } else {
    //         console.log("error");
    //         setNotification('Error encountered while submitting');
    //         setShowAlert(true);
    //       }
    //     } else {
    //       console.log(response.statusText);
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //     console.error('Error uploading file:', error);
    //     // Handle error
    //   }

    // }

    const buttons = document.querySelectorAll('.isBtnPromo'); // Adjust selector according to your HTML structure
    buttons.forEach((button1) => {
      console.log("button1", button1);
      button1.addEventListener('click', handleButtonClick);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', handleButtonClick);
      });
    };
  });

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="promos-modal"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >

          {showPart == 1 ?
            (<div>
              <button onClick={() => toggleParts(2)} className='promos-action-btn btn btn-success'>Create New Promo</button>
              <button onClick={() => toggleParts(3)} className='promos-action-btn btn btn-success'>Use Saved Templates</button>
            </div>)
            : showPart == 2 ?
              (<button onClick={() => toggleParts(1)} className='promos-action-btn btn btn-success'>Show My Promos</button>) :
              showPart == 3 ?
                (<button onClick={() => toggleParts(1)} className='promos-action-btn btn btn-success'>Show My Promos</button>)
                : (<></>)
          }

          {showPart == 1 ? (
            <div dangerouslySetInnerHTML={{ __html: mypromos }} />
          ) : showPart == 2 ? (
            <PromoForm memberid={props.memberid} me={props.me} onHide={
              function () {
                props.onHide();
                setNotification('Promo Details submitted successfully');
                setShowAlert(true);
                setShowPart(1);
              }
            } formid={formid} formtype={formtype} ></PromoForm>
          ) : showPart == 3 ? <div dangerouslySetInnerHTML={{ __html: templates }} /> : <div></div>}
        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {/* {notification && <div>{notification}</div>} */}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default PromoModal;