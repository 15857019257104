import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import UserInfoModal from "./UserInfoModal";
import UserPinTopicModal from "./UserPinTopicModal";

function UserTopic(props) {

  const [minutes, setMinutes] = useState(null);
  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState(null);
  const [deftopics, setDeftopics] = useState([]);
  const [defsubtopics, setDefsubtopics] = useState([]);

  const [seltopic, setSeltopic] = useState([]);
  const [selsubtopic, setSelsubtopic] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);


  // Modal
  const [modalShow, setModalShow] = React.useState(false);

  // useEffect(() => {

  //   const formData = new FormData(); 
  //   formData.append('type', "new");
  //   formData.append('me', props.me);
  //   formData.append('memberId', props.memberid);

  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(webUrl + "/userStats.php", {
  //         method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
  //         body: formData,
  //       }); 
  //       console.log("Data --:", response.body);
  //       // console.log("Result --:", result);    

  //       if (response.ok) {   
  //         const result = await response.json();      
  //         console.log("Result Data:", result);    
  //         if (result.success) {   
  //           console.log("success");          
  //           console.log(result);  
  //           setMinutes(result.data.minutes);            
  //           if(result.data.mlinfo.milestone_popup != ""){ 
  //             setMilestone(result.data.mlinfo.milestone_popup); 
  //             setHtml(result.data.mlinfo.html);    
  //             setModalShow(true);  
  //           }            
  //         } else {     
  //           console.log("error");  
  //         }     
  //       } else {     
  //         console.log(response.statusText);     
  //       }

  //       // if (response.ok) {    
  //       //   const result = await response.json();   
  //       //   console.log("Data:", result);
  //       //   }     
  //       // if (!response.ok) {     
  //       //   throw new Error('Failed to fetch data');  
  //       // }   
  //       // const jsonData = await response.json();     
  //       // setData(jsonData);   
  //     } catch (error) {   
  //       console.error('Error fetching data:', error); 
  //     } 
  //   };   

  //   fetchData();
  // }, []); // Empty dependency array means this effect runs only once on component mount

  function handleClick() {
    //   console.log("Button clicked!");
    // setModalShow(true);
    // setIsLoaded(true);

    const formData = new FormData();
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
            if (result.topics != "") {
              // setMilestone(result.data.mlinfo.milestone_popup);
              setHtml(result.html);
              console.log(result.topics);
              console.log(result.subtopics);
              setDeftopics(result.topics);
              setDefsubtopics(result.subtopics);
              setSeltopic(result.topic_sel);
              setSelsubtopic(result.subtopic_sel);
              setModalShow(true);
              setIsLoaded(true);
            }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }


  function handleHide() {
    setModalShow(false)
    setHtml("");
  }

  return (
    <div>
      <span className='' onClick={() => handleClick()}><img src="images/topic.png" /></span>
      {isLoaded ? <UserPinTopicModal show={modalShow} onHide={() => handleHide()} deftopics={deftopics} defsubtopics={defsubtopics} seltopic={seltopic} selsubtopic={selsubtopic} type={"load-topics"} memberid={props.memberid} html={html} /> : null}
    </div>
  );
}

export default UserTopic;