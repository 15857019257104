import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function ReportPromoModal(props) {
  
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  // const [show, setShow] = useState(true);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let promoid = "";
		let pdisp = document.querySelectorAll('.promo-player-display');
		if (pdisp != undefined && pdisp.length > 0) {
			promoid = pdisp[0].getAttribute('data-promo-id');
      // console.log(promoid);
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', message);
    formData.append('type', "new");
    formData.append('me', props.me ?? "");
    formData.append('idToCall', props.idToCall ?? "");
    formData.append('memberId', props.memberId ?? "");
    formData.append('calltype', props.calltype ?? "");
    formData.append('roomRowId', props.roomRowId ?? "");
    formData.append('roomId', props.roomId ?? "");
    formData.append('caller', props.caller ?? "");
    formData.append('promoid', promoid ?? "");

    console.log("submit report user", formData);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetch(webUrl + "/reportPromo.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");
          setNotification('Form submitted successfully');
          setShowAlert(true);
          props.onHide();
        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    }

  }


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="reportPromoModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Report promo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} value={message} onChange={handleMessageChange} />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Screenshot (optional)</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button type="submit">Send</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading && <div className=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default ReportPromoModal;