import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function UnlockRewardsModal(props) {

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleModalClose = () => {
    // props.onHide();
    // setFile(null);
    // setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  useEffect(() => { });

  
  const cpaBtn = (type) => {
    props.onOpenCPA(type);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="unlock-rewards-modal"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          {/* <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} /> */}

          <div className='text-center'>
          <h2>Unlock Rewards Early</h2>
          <br/>
          <Button variant="info" className="cpabtn cpa-adpoints" onClick={() => cpaBtn("adpoints")} >Earn Free Ad Points</Button> <br/>
          <Button variant="success" className="cpabtn cpa-minutes" onClick={() => cpaBtn("minutes")} >Earn Free Minutes</Button>  <br/>
          <Button variant="warning" className="cpabtn cpa-prodpoints" onClick={() => cpaBtn("prodpoints")} >Earn Free Product Points</Button>  <br/>
          <Button variant="danger" className="cpabtn cpa-chance" onClick={() => cpaBtn("chance")} >Earn Free Chance Enhancers</Button>  <br/>
          </div>

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default UnlockRewardsModal;