import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import UnlockRewardsModal from './UnlockRewardsModal';
import CPAModal from "./CPAModal";
import GCModal from "./GCModal";
import RedeemProdPointModal from "./RedeemProdPointModal";

function VipModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [html, setHtml] = useState(null);
  const [showWaiting, setShowWaiting] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [isVip, setIsVip] = React.useState(false);
  const [vipFrom, setVipFrom] = React.useState("");
  const [vipTo, setVipTo] = React.useState("");
  const [vipStatus, setVipStatus] = React.useState("");

  // const [unlockmodalShow, setUnlockmodalShow] = React.useState(false);
  // const [cpamodalShow, setCpamodalShow] = React.useState(false);
  // const [cpaType, setCpaType] = useState('');

  // const [gcmodalShow, setGcmodalShow] = React.useState(false);
  // const [gcmml, setGcmml] = React.useState("");

  // const [productpoints, setProductpoints] = useState(0);

  // const [redeemProdpointModalShow, setRedeemProdpointModalShow] = React.useState(false);


  // const [uecount, setUecount] = useState(0)


  // const [show, setShow] = useState(true);

  /*
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }; */


  const handleSubmit = async (event) => {
    // event.preventDefault();
    // setLoading(true);

    // const formData = new FormData();
    // formData.append('file', file);
    // formData.append('message', message);
    // formData.append('type', "new");
    // formData.append('me', props.me);
    // formData.append('idToCall', props.idToCall);
    // formData.append('memberId', props.memberId);
    // formData.append('calltype', props.calltype);
    // formData.append('roomRowId', props.roomRowId);
    // formData.append('roomId', props.roomId);
    // formData.append('caller', props.caller);

    // // console.log("submit report user", formData);
    // // for (const [key, value] of formData.entries()) {
    // //   console.log(key, value);
    // // }
    // try {
    //   const response = await fetch(webUrl + "/reportUser.php", {
    //     method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
    //     body: formData,
    //     // headers: {
    //     // 	'Content-Type': 'application/json',
    //     // },
    //     // body: JSON.stringify(requestData),
    //   });
    //   setLoading(false);
    //   if (response.ok) {
    //     const result = await response.json();
    //     console.log("Data:", result);
    //     if (result.success) {
    //       console.log("success");
    //       setNotification('Form submitted successfully');
    //       setShowAlert(true);
    //       props.onHide();
    //     } else {
    //       console.log("error");
    //       setNotification('Error encountered while submitting');
    //       setShowAlert(true);
    //     }
    //   } else {
    //     console.log(response.statusText);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   console.error('Error uploading file:', error);
    //   // Handle error
    // }

  }


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };



  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "is-vip-user");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        // console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          // console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            let h = "";
            if (result.vip == "yes") {
              setIsVip(true);
              setVipFrom(result.from_disp);
              setVipTo(result.to_disp);
            }
            setVipStatus(result.vip_status);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // return () => { };
  });


  const purchase = async() => {
    // setUnlockmodalShow(true);
    // props.onHide();

    if (vipStatus == "" && !isVip) {
      // go to 0.99 for 2 days
      console.log("0.99 for 2 days");
    }
    else if (vipStatus != "" && isVip) {
      // extend vip end date
      // go to 4.99 per month
      console.log("is already vip - extend by month");
    }
    else if (vipStatus != "" && !isVip) {
      // enter new vip start and end date
      // go to 4.99 per month
      console.log("renew vip after expired - start and end date");
    }

    const formData = new FormData();
    formData.append('type', "vip-pay");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);
    formData.append('vip_status', vipStatus);
    formData.append('vip', isVip);

    // console.log("submit report user", formData);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    
    try {
      const response = await fetch(webUrl + "/vipSettings.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");
          setShowWaiting(true);
          window.open(result.paymentUrl, '_blank');
          check_for_vip_payment_status(result.memberid, result.vippay_id);

        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    }
    // window.open(result.paymentUrl, '_blank');


  };




  
  let timerOn = true;
  const check_for_vip_payment_status = async (memberid, vippay_id) => {

    // let shippingfee = document.querySelectorAll('.shippingfee');
    // let congrats = document.querySelectorAll('.congrats');

    // timerOn = true;
    console.log('Function executed after 2 seconds', memberid, vippay_id);

    const formData = new FormData();
    formData.append('vippay_id', vippay_id);
    formData.append('memberid', memberid);
    // formData.append('sendto', sendto);
    formData.append('type', 'vip-payment-status');
    try {
      const response = await fetch(webUrl + "/checkPaymentStatus.php", {
        method: 'POST',
        body: formData,
      });
      // setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          if (result.status == 'paid') {
            console.log("payment success");
            timerOn = false;
            setShowWaiting(false);
            setShowCongrats(true);
            // setNotification('Payment received ! Gift sent successfully');
            // setShowAlert(true);
            // props.onHide();
            // congrats[0].classList.add("show");
            // shippingfee[0].classList.remove("show");

          } else {
            console.log("waiting for payment");
          }
        } else {
          console.log("error");
          setNotification('Error encountered while checking payment status');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
      // Handle error
    }

    if (timerOn == true) {
      setTimeout(() => check_for_vip_payment_status(memberid, vippay_id), 5000);
    }

  }

  const vipSettings = () => {
    // setUnlockmodalShow(true);
    props.onHide();
    props.openVipSettings();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ml-reached"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {/* <div className='text-center'>
            <Button variant="danger" className="unlock-early" onClick={() => unlockearly()}>Unlock Rewards Early</Button>
          </div> */}

          <div className='text-center vip-features'>
            <h2 className="sec-title">VIP Features</h2>
            <h5 className="sec-subtitle">Included</h5>
            <div className="spacer"></div>

            <div className="perks box">
              <div className="content">
                <h4 className="tit">PROMO PERKS</h4>
                <p>Upload Upto 3 images</p>
                <p>Clickable links</p>
                <p>Target countries</p>
                <p>Target genders</p>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">PERKS</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-star.png" className="circle" />
                <img src="images/icon-tv.png" className="icon" />
              </div>
            </div>

            <div className="gender box">
              <div className="content">
                <h4 className="tit">Choose a gender to</h4>
                <h6 className="subtit">connect with</h6>
                {/* <h4 className="head">OR</h4> */}
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Pick Gender</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-pink.png" className="circle" />
                <img src="images/icon-gender.png" className="icon" />
              </div>
            </div>


            <div className="adpoints box">
              <div className="content">
                <h4 className="tit">Free 200 AD Points a Month</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">200 Ad Points</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-star.png" className="circle" />
                <img src="images/icon-adpoints.png" className="icon" />
              </div>
            </div>

            <div className="promoads box">
              <div className="content">
                <h4 className="tit">Disable / Enable Promo Ads</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Disable Promos</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-star.png" className="circle" />
                <img src="images/icon-promoads.png" className="icon" />
              </div>
            </div>

            <div className="gift box">
              <div className="content">
                <h4 className="tit">Ability to receive rewards from users</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Get Gifted</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-orange.png" className="circle" />
                <img src="images/icon-gift.svg" className="icon" />
              </div>
            </div>


            <div className="shipfree box">
              <div className="content">
                <h4 className="tit">Free shipping on 2 products a month</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Ship Free</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-green.png" className="circle" />
                <img src="images/icon-truck.svg" className="icon" />
              </div>
            </div>


            <div className="pin box">
              <div className="content">
                <h4 className="tit">Pin Social Pay Apps on screen</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Pin Anything</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-red.png" className="circle" />
                <img src="images/icon-pin.svg" className="icon" />
              </div>
            </div>


            <div className="spin box">
              <div className="content">
                <h4 className="tit">Spin Legendary Items</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Spin Legendary</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-blue.png" className="circle" />
                <img src="images/icon-diamond.png" className="icon" />
              </div>
            </div>

            <div className="chanceenhance box">
              <div className="content">
                <h4 className="tit">Higher Chance Enhancer % 35+</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">High Chance</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-star.png" className="circle" />
                <img src="images/icon-fire.svg" className="icon" />
              </div>
            </div>

            <div className="cap box">
              <div className="content">
                <h4 className="tit">30 Minutes Cap Per User</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Higher Cap</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-darkyellow.png" className="circle" />
                <img src="images/icon-cap.png" className="icon" />
              </div>
            </div>

            <div className="spin2 box">
              <div className="content">
                <h4 className="tit">2nd Spin Attempt on Rare/Legend Items</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">2nd Chance</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-darkbrown.png" className="circle" />
                <img src="images/icon-spin2.png" className="icon" />
              </div>
            </div>

            <div className="topic box">
              <div className="content">
                <h4 className="tit">Add Custom Topics</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Add Topics</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-black.png" className="circle" />
                <img src="images/icon-topic.png" className="icon" />
              </div>
            </div>

            <div className="adpoints2x box">
              <div className="content">
                <h4 className="tit">Earn 2x Ad Points During Video Calls</h4>
                <div className="feature-btn-wrap">
                  <Button className="feature-btn">Ad Points</Button>
                </div>
              </div>
              <div className="bg">
                <img src="images/circle-orange.png" className="circle" />
                <img src="images/icon-star.png" className="icon" />
              </div>
            </div>

            <div className="spacer"></div>

            {isVip && <div><h4>VIP Subscription Active</h4> <h6>From: {vipFrom} To: {vipTo}</h6><br/></div>}

            <div className="purchase-wrap">
              <span>$0.99 for 2 days then $4.99 a month</span>
              <Button className="purchase-btn" onClick={() => purchase()}>Purchase</Button>
              <br /><br />
              <Button className="vip-settings-btn" onClick={() => vipSettings()}>Open Vip Settings</Button>
            </div>


            {showWaiting == true && <div className='vip-pay-waiting'>
              <div class='wrap'>
                {/* <span className='isBtn close-giftpay-waiting' data-value='close-giftpay-waiting'>Close</span> */}
                <h4 className='tit'>VIP Subscription Payment</h4>
                <h5 className='subtit'>Waiting for confirmation from payment gateway</h5>
              </div>
            </div>}

            {showCongrats == true && <div className='vip-pay-success'>
              <div class='wrap'>
                {/* <span className='isBtn close-giftpay-success' data-value='close-giftpay-success'>Close</span> */}
                <h4 className='tit'>VIP Subscription Payment Successful</h4>
                <h5 className='subtit'>Your payment for VIP Subscription was successful. Thank You!</h5>
              </div>
            </div>}

          </div>

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default VipModal;