import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function GCModal(props) {

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [popup, setPopup] = useState(false);


  const handleModalClose = () => {
    // props.onHide();
    // setFile(null);
    // setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  useEffect(() => {

    // if (props.cpaType == "adpoints") {
    setTitle("Your User ID is: " + props.memberid);
    setUrl("https://form.jotform.com/242126063331141?user_id=" + props.memberid);
    setShowIframe(true);
    // }
    // else {
    //   setTitle("");
    //   setUrl("");
    //   setShowIframe(false);
    // }


  });


  const popupAction = async (action) => {
    // console.log(action);
    if (action == "yes") {
      await submit_gcform_filled("");
    }
    else if (action == "no") {
      setPopup(false);
    }
  };


  const submit_gcform_filled = async (id) => {

    const shipData = new FormData();
    shipData.append('type', "gcform-filled");
    // shipData.append('id', id);
    shipData.append('memberid', props.memberid);
    shipData.append('gcmml', props.gcmml);

    // console.log(shipData);
    for (const [key, value] of shipData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetch(webUrl + "/userStats.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: shipData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");
            // let form = document.querySelectorAll('.shipping-details[data-id="' + id + '"]');
            // form[0].classList.remove("show");
            setNotification('Details submitted successfully');
            setShowAlert(true);
            // props.onHide();
        } else {
          console.log("error");
          setNotification('Something went wrong while submitting');
          setShowAlert(true);
        }
        setPopup(false);
        props.onHide();
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="unlock-rewards-modal"
        backdrop="static"
      >
        <Modal.Header>
          <Button onClick={() => setPopup(true)} variant="">X Close</Button>
        </Modal.Header>
        <Modal.Body >
          {/* <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} /> */}
          <h2 className='text-center'>{title}</h2>
          {showIframe ?
            <iframe className='gc-iframe' src={url}></iframe>
            : null}

          {popup ? <div className='gcform-filled'>
            <div className='text-center'>
              <h4>Did you complete the form and clicked submit at the very end?</h4>
              <div className='spacer'></div>
              <Button onClick={() => popupAction("yes")} variant="success">Yes</Button> &nbsp; &nbsp; <Button onClick={() => popupAction("no")} variant="danger">No</Button>
              <div className='spacer'></div>
              <h5>If there is a typo in the form please contact support.</h5>
            </div>
          </div> : null}

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default GCModal;