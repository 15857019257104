import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function CPAModal(props) {

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [button, setButton] = useState('');



  const handleModalClose = () => {
    // props.onHide();
    // setFile(null);
    // setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };


  useEffect(() => {

    if (props.cpaType == "adpoints") {
      setTitle("Bonus Ad Points");
      setUrl("https://www.kiwiwall.com/wall/z37G009o2PnWNrdm7sAXM2PhzNVBsY46/" + props.memberid);
      setShowIframe(true);
      setButton("");
    }

    else if (props.cpaType == "minutes") {
      setTitle("Bonus Minutes");
      setUrl("https://www.kiwiwall.com/wall/4WBqjnCdjxoPqMBysPCyMb8mboyYgWPm/" + props.memberid);
      setShowIframe(true);
      setButton("");
    }

    else if (props.cpaType == "prodpoints") {
      setTitle(" Product Points: +" + props.productpoints);
      setUrl("https://www.kiwiwall.com/wall/uBoq96LmvU6MKhBU35MhHoiE1XwpShXc/" + props.memberid);
      setShowIframe(true);
      setButton("Reach 2 product points! Unlock Free Product!");
    }

    else if (props.cpaType == "chance") {
      setTitle("Bonus Chance Enhancers");
      setUrl("https://www.kiwiwall.com/wall/XC1wchdaKphpkKdQGz2BuijIxsYqrk6H/" + props.memberid);
      setShowIframe(true);
      setButton("");
    }

    else {
      setTitle("");
      setUrl("");
      setButton("");
      setShowIframe(false);
    }


  });

  const buttonClicked = () => {
    if (props.cpaType == "prodpoints" && props.productpoints >= 2) {
      props.onHide();
      props.openRedeemProdPoint();
    } else {

    }
  };

  // const cpaBtn = (type) => {
  //   props.onOpenCPA(type);
  // };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="unlock-rewards-modal"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          {/* <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} /> */}
          <h2 className='text-center'>{title}</h2>
          {button != "" ? <div className='text-center'><Button variant="success" className={props.productpoints >= 2 ? " enabled " : " disabled "}  onClick={() => buttonClicked()}>{button}</Button></div> : null}

          {showIframe ?
            <iframe className='cpa-iframe' src={url}></iframe>
            : null}
          <div className='cpa-notice'>
            <p><span className='text-danger'>NOTICE 1:</span> These offers may not guarantee {title}. Offers are from companies seeking quality leads/installs, etc. Your offer may or may not qualify, but it is worth a try.
            <br/>
            <span className='text-danger'>NOTICE 2:</span> To view if a offer was approved/credited to your account click the “My Earnings” above on that page.</p>
          </div>

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default CPAModal;