import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function UserPinTopicModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [updateDoc, setUpdateDoc] = useState(0);

  // const [selectedValue, setSelectedValue] = useState('');
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSubtopic, setSelectedSubtopic] = useState('');
  const [showPinbtn, setShowPinbtn] = useState(false);
  const [savedhtml, setSavedhtml] = useState('');


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  useEffect(() => {

    setSavedhtml("");

    setTopics(props.deftopics);
    setSubtopics(props.defsubtopics);

    setSelectedTopic(props.seltopic);
    setSelectedSubtopic(props.selsubtopic);

  }, []);


  function handleChange(e) {
    setSelectedTopic(e.target.value);

    let v = e.target.value;

    if (v != 0 && v != "") {

      const formData = new FormData();
      formData.append('type', "get-subtopics");
      formData.append('topic', v);
      formData.append('memberId', props.memberid);

      const fetchData = async () => {
        try {
          const response = await fetch(webUrl + "/userTopic.php", {
            method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
            body: formData,
          });
          console.log("Data --:", response.body);
          // console.log("Result --:", result);    

          if (response.ok) {
            const result = await response.json();
            console.log("Result Data:", result);
            if (result.success) {
              console.log("success");
              console.log(result);
              // setMinutes(result.data.minutes);
              if (result.subtopics != "") {
                // setMilestone(result.data.mlinfo.milestone_popup);
                // setHtml(result.html);
                // console.log(result.topics);
                setSubtopics(result.subtopics);
                // setModalShow(true);
                // setIsLoaded(true);
              }
            } else {
              console.log("error");
            }
          } else {
            console.log(response.statusText);
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();

    } else {
      setSubtopics([]);
    }

  }


  function handleSubtopicChange(e) {
    setSelectedSubtopic(e.target.value);
    console.log(e.target.value);
    setShowPinbtn(true);
  }

  function unsavePin() {
    setSelectedTopic("");
    setSelectedSubtopic("");
    savePin("unpin");
  }


  function savePin(action = "pin") {


    const formData = new FormData();
    formData.append('type', "save-pin");
    formData.append('memberid', props.memberid);
    formData.append('topic', selectedTopic);
    formData.append('subtopic', selectedSubtopic);
    formData.append('action', action);

    setSavedhtml('...');

    const savePinApi = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
            if (result.html != "") {
              setSavedhtml(result.html);
            }
            if (action == "unpin") {
              props.onHide();
              setSavedhtml("");
              // setNotification(result.html);
            }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    savePinApi();


  }


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ml-reached"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} />

          <h3>Pin A Topic</h3>
          <h5>Gain 5% Chance Enhancer When You Pin A Topic</h5>
          <p>Discuss controversial topics to liven up chats, ask questions, play games, or pin any topic. Remember, be respectful. Violations will lead to a ban. Check rules. Pin your opinions/questions on screen to share and earn 1.5x minutes.</p>

          <select value={selectedTopic} onChange={(e) => handleChange(e)}>
            <option key="pin" value={0}>Pin a topic</option>
            {topics != undefined ? topics.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            )) : null}
          </select>

          <br />
          <br />
          {subtopics.length > 0 ? <select value={selectedSubtopic} onChange={(e) => handleSubtopicChange(e)}>
            <option key="pin" value={0}>Pin a sub topic</option>
            {subtopics != undefined ? subtopics.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            )) : null}
          </select> : null}

          {props.deftopics.length > 0 && subtopics.length && selectedSubtopic != "" && selectedSubtopic != 0 && showPinbtn == true ?
            <div>
              <br /><Button onClick={() => savePin()}>Pin this to your profile</Button> &nbsp; &nbsp;
            </div>
            : null}

            {props.deftopics.length > 0 && subtopics.length && selectedSubtopic != "" && selectedSubtopic != 0 ?
            <div>
              <br />
              <Button onClick={() => unsavePin()} variant='danger'>UnPin topic</Button>
            </div>
            : null}

          <br />
          {savedhtml}


        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default UserPinTopicModal;