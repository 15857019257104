import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import UserInfoModal from "./UserInfoModal";
import UserPinTopicModal from "./UserPinTopicModal";
// import { Button } from "bootstrap";
import Button from 'react-bootstrap/Button';
import SendGiftModal from "./SendGiftModal";

function SendGift(props) {

  const [minutes, setMinutes] = useState(null);
  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showtopic, setShowtopic] = useState(true);
  const [tagline, setTagline] = useState("");

  const [snapchat, setSnapchat] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [throne, setThrone] = useState('');
  const [paypal, setPaypal] = useState('');
  const [cashapp, setCashapp] = useState('');
  const [amazonwl, setAmazonwl] = useState('');
  const [instagram, setInstagram] = useState('');




  // Modal
  const [modalShow, setModalShow] = React.useState(false);
  const [isVip, setIsVip] = React.useState(false);
  const [sendto, setSendto] = React.useState("");

  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "check-vip-user");
    formData.append('me', props.me);
    formData.append('memberid', props.memberId);
    formData.append('idtocall', props.idToCall);
    formData.append('roomid', props.roomId);
    formData.append('caller', props.caller);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/sendGift.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        // console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          // console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            let h = "";
            if (result.vip == "yes") {
              setIsVip(true);
              setSendto(result.sendto);
            }
            setHtml(h);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }); // Empty dependency array means this effect runs only once on component mount

  /*
  function handleClick() {
    //   console.log("Button clicked!");
    // setModalShow(true);
    // setIsLoaded(true);

    const formData = new FormData();
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('memberId', props.memberId);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
            if (result.topics != "") {
              // setMilestone(result.data.mlinfo.milestone_popup);
              setHtml(result.html);
              console.log(result.topics);
              setOptions(result.topics);
              setModalShow(true);
              setIsLoaded(true);
            }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }
  */

  return (
    <div>
      {<div>
        {modalShow ? <SendGiftModal show={modalShow} onHide={() => setModalShow(false)} memberid={props.memberId} sendto={sendto} /> : null}
        <div className='send-gift'>
          {isVip && <Button onClick={() => setModalShow(true)}><img src="images/gift.svg" /></Button>}
        </div>
      </div>}
    </div>
  );
}

export default SendGift;