import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import UnlockRewardsModal from './UnlockRewardsModal';
import CPAModal from "./CPAModal";
import GCModal from "./GCModal";
import RedeemProdPointModal from "./RedeemProdPointModal";
import { Input } from "@mui/material";

function VipSettingsModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [html, setHtml] = useState(null);
  const [snapchat, setSnapchat] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [throne, setThrone] = useState('');
  const [paypal, setPaypal] = useState('');
  const [cashapp, setCashapp] = useState('');
  const [amazonwl, setAmazonwl] = useState('');
  const [instagram, setInstagram] = useState('');
  const [prefergender, setPreferGender] = useState('Both');
  const [promoad, setPromoad] = useState('yes');
  const [pinopen, setPinopen] = useState(false);
  const [activepin, setActivepin] = useState("");
  const [newGifts, setNewGifts] = useState(0);

  const [redeemProdpointModalShow, setRedeemProdpointModalShow] = React.useState(false);

  const handleTwitterChange = (event) => {
    setTwitter(event.target.value);
  };
  const handleSnapchatChange = (event) => {
    setSnapchat(event.target.value);
  };
  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };
  const handleTiktokChange = (event) => {
    setTiktok(event.target.value);
  };
  const handleThroneChange = (event) => {
    setThrone(event.target.value);
  };
  const handlePaypalChange = (event) => {
    setPaypal(event.target.value);
  };
  const handleAmazonwlChange = (event) => {
    setAmazonwl(event.target.value);
  };
  const handleCashappChange = (event) => {
    setCashapp(event.target.value);
  };


  // useEffect(() => {


  //   return () => { };
  // });


  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "fetch-vip-settings");
    // formData.append('me', props.me);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            if (result.prefer_gender != "") {
              setPreferGender(result.prefer_gender);
            }

            if (result.show_promo_ads != "") {
              setPromoad(result.show_promo_ads);
            }

            if (result.pin_social != "") {

              setInstagram(result.pin_social.instagram);
              setTwitter(result.pin_social.twitter);
              setCashapp(result.pin_social.cashapp);
              setTiktok(result.pin_social.tiktok);
              setThrone(result.pin_social.throne);
              setPaypal(result.pin_social.paypal);
              setAmazonwl(result.pin_social.amazonwl);
              setSnapchat(result.pin_social.snapchat);
              setActivepin(result.pin_active);
            }


            if (result.new_gifts != undefined) {
              setNewGifts(result.new_gifts)
            }

          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);   
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs only once on component mount




  const handlePinSave = () => {

    // console.log(instagram, cashapp);
    let h = "";
    h += "instagram!!!" + instagram + "|||";
    h += "cashapp!!!" + cashapp + "|||";
    h += "amazonwl!!!" + amazonwl + "|||";
    h += "tiktok!!!" + tiktok + "|||";
    h += "twitter!!!" + twitter + "|||";
    h += "snapchat!!!" + snapchat + "|||";
    h += "throne!!!" + throne + "|||";
    h += "paypal!!!" + paypal + "|||";

    console.log(h);


    const formData = new FormData();
    formData.append('type', "set-vip-pinsocial");
    formData.append('pin_social', h);
    formData.append('memberid', props.memberid);

    const submitPinSocial = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data: ", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    submitPinSocial();
  };

  const handlePinChange = (p) => {
    console.log(p);

    const formData = new FormData();
    formData.append('type', "set-vip-active-pin");
    formData.append('pin_active', p);
    formData.append('memberid', props.memberid);

    const submitActivePin = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data: ", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            setActivepin(p);
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    submitActivePin();

  }

  const checkPreferGender = (g) => {
    setPreferGender(g)
    console.log(g, props.memberid);


    const formData = new FormData();
    formData.append('type', "set-vip-prefergender");
    formData.append('prefergender', g);
    formData.append('memberid', props.memberid);

    const submitPreferGender = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data: ", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    submitPreferGender();

  };



  const checkPromoad = (p) => {
    setPromoad(p)

    console.log(p, props.memberid);


    const formData = new FormData();
    formData.append('type', "set-vip-show_promo_ad");
    formData.append('promo_ad', p);
    formData.append('memberid', props.memberid);

    const submitPromoad = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data: ", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    submitPromoad();


  };

  const togglePinOpen = () => {
    setPinopen(!pinopen)

  };


  const redeemGifts = (event) => {
    console.log("redeem");
    setRedeemProdpointModalShow(true);
  };


  const hideAllModals = () => {
    setRedeemProdpointModalShow(false)
    props.onHide();
    // setNotification('Details submitted successfully');
    // setShowAlert(true);
  };


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ml-reached"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div dangerouslySetInnerHTML={{ __html: html }} />

          <div className='text-center vip-settings'>
            <h2 className="sec-title">VIP Features</h2>
            <h5 className="sec-subtitle">Enable / Disable</h5>
            <div className="spacer"></div>

            <div className="gender">

              <div className={"male box " + (prefergender == "Male" ? " checked " : "")} onClick={() => checkPreferGender("Male")}>
                <h5 className="tit">Male</h5>
                <div className="check"><img src="images/check.png" className="" /></div>
                <div className="icon"><img src="images/male.svg" className="" /></div>
              </div>
              <div className={"both box " + (prefergender == "Both" ? " checked " : "")} onClick={() => checkPreferGender("Both")}>
                <h5 className="tit">Both</h5>
                <div className="check"><img src="images/check.png" className="" /></div>
              </div>
              <div className={"female box " + (prefergender == "Female" ? " checked " : "")} onClick={() => checkPreferGender("Female")}>
                <h5 className="tit">Female</h5>
                <div className="check"><img src="images/check.png" className="" /></div>
                <div className="icon"><img src="images/female.svg" className="" /></div>
              </div>

            </div>

            <div className="promoads">
              <h4 className="tit">Show PROMO Ads</h4>
              <Button className={"promo-yes-btn " + (promoad == "yes" ? " checked " : "")} onClick={() => checkPromoad("yes")}>Yes<img src="images/check.png" className="" /></Button>
              <Button className={"promo-no-btn " + (promoad == "no" ? " checked " : "")} onClick={() => checkPromoad("no")}>No<img src="images/check.png" className="" /></Button>
            </div>


            <div className="pin-social">
              <h4 className="tit">Pin Socials / Pay Apps on Screen</h4>
              <h6 className="subtit">Please enter only platform id</h6>


              <ul className={" " + (pinopen == true ? " open " : "")} >
                <div className="pin-dd" onClick={() => togglePinOpen()}>
                  <img src="images/icon-arrow.png" className="dd" />
                </div>
                <li className={"pinsocial " + (activepin == "cashapp" ? " active " : "")}>
                  <img src="images/cashapp.png" />
                  <span className="url">https://cashapp.com/</span>
                  <input type="text" name="cashapp" value={cashapp} onBlur={handlePinSave} onChange={handleCashappChange} className="form-control" id="exampleInputCashapp1" placeholder="Enter cashapp id" />
                  <span className={"setpin " + (activepin == "cashapp" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("cashapp")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "instagram" ? " active " : "")}>
                  <img src="images/instagram.png" />
                  <span className="url">https://instagram.com/</span>
                  <input type="text" name="instagram" value={instagram} onBlur={handlePinSave} onChange={handleInstagramChange} className="form-control" id="exampleInputInstagram1" placeholder="Enter instagram id" />
                  <span className={"setpin " + (activepin == "instagram" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("instagram")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "amazonwl" ? " active " : "")}>
                  <img src="images/amazonwl.png" />
                  <span className="url">https://www.amazon.com/</span>
                  <input type="text" name="amazonwl" value={amazonwl} onBlur={handlePinSave} onChange={handleAmazonwlChange} className="form-control" id="exampleInputAmazonwl1" placeholder="Enter amazon wishlist id" />
                  <span className={"setpin " + (activepin == "amazonwl" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("amazonwl")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "paypal" ? " active " : "")}>
                  <img src="images/paypal.png" />
                  <span className="url">https://paypal.me/</span>
                  <input type="text" name="paypal" value={paypal} onBlur={handlePinSave} onChange={handlePaypalChange} className="form-control" id="exampleInputPaypal1" placeholder="Enter paypal id" />
                  <span className={"setpin " + (activepin == "paypal" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("paypal")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "throne" ? " active " : "")}>
                  <img src="images/throne.png" />
                  <span className="url">https://throne.com/</span>
                  <input type="text" name="throne" value={throne} onBlur={handlePinSave} onChange={handleThroneChange} className="form-control" id="exampleInputThrone1" placeholder="Enter throne id" />
                  <span className={"setpin " + (activepin == "throne" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("throne")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "tiktok" ? " active " : "")}>
                  <img src="images/tiktok.png" />
                  <span className="url">https://www.tiktok.com/@</span>
                  <input type="text" name="tiktok" value={tiktok} onBlur={handlePinSave} onChange={handleTiktokChange} className="form-control" id="exampleInputTiktok1" placeholder="Enter tiktok id" />
                  <span className={"setpin " + (activepin == "tiktok" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("tiktok")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "twitter" ? " active " : "")}>
                  <img src="images/twitter.png" />
                  <span className="url">https://x.com/</span>
                  <input type="text" name="twitter" value={twitter} onBlur={handlePinSave} onChange={handleTwitterChange} className="form-control" id="exampleInputTwitter1" placeholder="Enter twitter id" />
                  <span className={"setpin " + (activepin == "twitter" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("twitter")} /></span>
                </li>
                <li className={"pinsocial " + (activepin == "snapchat" ? " active " : "")}>
                  <img src="images/snapchat.png" />
                  <span className="url">https://www.snapchat.com/</span>
                  <input type="text" name="snapchat" value={snapchat} onBlur={handlePinSave} onChange={handleSnapchatChange} className="form-control" id="exampleInputSnapchat1" placeholder="Enter snapchat id" />
                  <span className={"setpin " + (activepin == "snapchat" ? " active " : "")}><img src="images/icon-pin.svg" className="pin" onClick={() => handlePinChange("snapchat")} /></span>
                </li>
              </ul>
            </div>


            <div className="chance-enhance">
              <img src="images/flames.gif" />
              <h4 className="tit">Chance <img src="images/info.png" className="info" /><br />Enhancer<br />35%+ </h4>
            </div>


            <div className="receive-products">
              <h4 className="tit">Receive Products from Users  <img src="images/info.png" className="info" /></h4>
              <div className="box vip-redeem-gifts" onClick={() => redeemGifts()}>
                <img src="images/icon-gift.svg" className="gift" />
                <span className="headno">{newGifts}</span>
                <span className="head">Rewards To<br />Redeem</span>
                {/* <img src="images/icon-onoff.png" className="onoff" /> */}
              </div>

            </div>

            <div className="vip-rewards">
              <h2>Add Custom Topics <img src="images/info.png" className="info" /></h2>
              <h2>200 Ad Points per month <img src="images/info.png" className="info" /></h2>
              <h2>Spin Legendary Items <img src="images/info.png" className="info" /></h2>
              <h2>30 Minute Cap Per user<img src="images/info.png" className="info" /></h2>
              <h2>2nd Try on Rare & Legendary Items<img src="images/info.png" className="info" /></h2>
              <h2>Free Shipping on 2 Items per month <img src="images/info.png" className="info" /></h2>
            </div>

            <div className="promo-perks">
              <h1>Promo Perks</h1>
              <h2>Upload 3 Images at once</h2>
              <h2>Post Clickable Links</h2>
              <h2>Target Country in Promo</h2>
              <h2>Target Genders in Promo</h2>
            </div>



          </div>

        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>

      <RedeemProdPointModal show={redeemProdpointModalShow} 
      onHide={() => setRedeemProdpointModalShow(false)} onHideAll={() => hideAllModals()} 
      memberid={props.memberid} redeem_type={"vip_gift"} ></RedeemProdPointModal>

    </>

  );
}

export default VipSettingsModal;