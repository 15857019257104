import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import UserInfoModal from "./UserInfoModal";

function UserStats(props) {

  const [minutes, setMinutes] = useState(null);
  const [adpoints, setAdpoints] = useState(null);
  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState(null);

  // Modal
	const [modalShow, setModalShow] = React.useState(false);
 
  useEffect(() => {

    const formData = new FormData(); 
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('memberId', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userStats.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        }); 
        console.log("****************Data --:**********", response);
        console.log("****************Data --:**********", response.body);
        // console.log("Result --:", result);    
        
        if (response.ok) {   
          const result = await response.json();      
          console.log("Result Data:", result);    
          if (result.success) {   
            console.log("success");          
            console.log("**********",result,"**********");  
            setMinutes(result.data.minutes);
            setAdpoints(result.data.adpoints);
            if(result.data.mlinfo.milestone_popup != ""){ 
              setMilestone(result.data.mlinfo.milestone_popup); 
              setHtml(result.data.mlinfo.html);    
              setModalShow(true);  
            }            
          } else {     
            console.log("error");  
          }     
        } else {     
          console.log(response.statusText);     
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);   
      } catch (error) {   
        console.error('Error fetching data:', error); 
      } 
    };   

    fetchData();
  }, []); // Empty dependency array means this effect runs only once on component mount
  
  return (
    <div>
      <span className='adpoints'><img src="images/star.png" /><span className='no'>{adpoints}</span> Ad Points</span>
      <br/>
      <span className='minutes'><img src="images/minutes.png" /><span className='no'>{minutes}</span> minutes</span>
      <UserInfoModal show={modalShow} onHide={() => setModalShow(false)} type={"milestone-reached"} memberid={props.memberid} milestone={milestone} html={html} />
    </div>
  );
}

export default UserStats;