import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import UserInfoModal from "./UserInfoModal";
import UserPinTopicModal from "./UserPinTopicModal";
// import { Button } from "bootstrap";
import Button from 'react-bootstrap/Button';

function UserPinnedSocialApps(props) {

  const [minutes, setMinutes] = useState(null);
  const [milestone, setMilestone] = useState(null);
  const [html, setHtml] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showtopic, setShowtopic] = useState(true);
  const [tagline, setTagline] = useState("");

  const [snapchat, setSnapchat] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [throne, setThrone] = useState('');
  const [paypal, setPaypal] = useState('');
  const [cashapp, setCashapp] = useState('');
  const [amazonwl, setAmazonwl] = useState('');
  const [instagram, setInstagram] = useState('');




  // Modal
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {

    const formData = new FormData();
    formData.append('type', "user-pinned-social-apps");
    formData.append('me', props.me);
    formData.append('memberid', props.memberId);
    formData.append('idtocall', props.idToCall);
    formData.append('roomid', props.roomId);
    formData.append('caller', props.caller);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/vipSettings.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        // console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          // console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            let h = "";

            if (result.pin_active != "") {

              if (result.pin_active == "instagram" && result.pin_social.instagram != "") {
                setInstagram(" https://www.instagram.com/" + result.pin_social.instagram);
              }
              else if (result.pin_active == "twitter" && result.pin_social.twitter != "") {
                setTwitter("https://x.com/" + result.pin_social.twitter);
              }
              else if (result.pin_active == "tiktok" && result.pin_social.tiktok != "") {
                setTiktok("https://www.tiktok.com/@" + result.pin_social.tiktok);
              }
              else if (result.pin_active == "cashapp" && result.pin_social.cashapp != "") {
                setCashapp("https://cashapp.com/" + result.pin_social.cashapp);
              }
              else if (result.pin_active == "paypal" && result.pin_social.paypal != "") {
                setPaypal("https://paypal.me/" + result.pin_social.paypal);
              }
              else if (result.pin_active == "amazonwl" && result.pin_social.amazonwl != "") {
                setAmazonwl("https://www.amazon.com/" + result.pin_social.amazonwl);
              }
              else if (result.pin_active == "throne" && result.pin_social.throne != "") {
                setThrone("https://throne.com/" + result.pin_social.throne);
              }
              else if (result.pin_active == "snapchat" && result.pin_social.snapchat != "") {
                setSnapchat("https://www.snapchat.com/add/" + result.pin_social.snapchat);
              }
            }





            // console.log(result.topic_details.name);
            // console.log(result.topic_details.gender);
            // setMinutes(result.data.minutes);            
            // if(result.data.mlinfo.milestone_popup != ""){ 
            //   setMilestone(result.data.mlinfo.milestone_popup); 
            setHtml(h);
            // let gender = result.topic_details.gender;
            // let topic = result.topic_details.name;

            // if (result.topic_details.name != undefined) {
            //   setHtml(result.topic_details.name);
            // }

            // if (result.topic_details.tagline != undefined) {
            //   setTagline(result.topic_details.tagline);
            // }
            //   setModalShow(true);  
            // }             
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }); // Empty dependency array means this effect runs only once on component mount

  function handleClick() {
    //   console.log("Button clicked!");
    // setModalShow(true);
    // setIsLoaded(true);

    const formData = new FormData();
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('memberId', props.memberId);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userTopic.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data --:", response.body);
        // console.log("Result --:", result);    

        if (response.ok) {
          const result = await response.json();
          console.log("Result Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            // setMinutes(result.data.minutes);
            if (result.topics != "") {
              // setMilestone(result.data.mlinfo.milestone_popup);
              setHtml(result.html);
              console.log(result.topics);
              setOptions(result.topics);
              setModalShow(true);
              setIsLoaded(true);
            }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }

  function hideTopic() {
    if (showtopic) {
      setShowtopic(false);
    } else {
      setShowtopic(true);
    }
  }

  return (
    <div>
      {<div>
        <div className='pins-social'>
          {instagram != "" ? <Button as="a" href={instagram} variant="transparent" target="_blank"><img src='images/instagram.png' /></Button> : ""}
          {snapchat != "" ? <Button as="a" href={snapchat} variant="transparent" target="_blank"><img src='images/snapchat.png' /></Button> : ""}
          {tiktok != "" ? <Button as="a" href={tiktok} variant="transparent" target="_blank"><img src='images/tiktok.png' /></Button> : ""}
          {twitter != "" ? <Button as="a" href={twitter} variant="transparent" target="_blank"><img src='images/twitter.png' /></Button> : ""}
          {amazonwl != "" ? <Button as="a" href={amazonwl} variant="transparent" target="_blank"><img src='images/amazonwl.png' /></Button> : ""}
          {throne != "" ? <Button as="a" href={throne} variant="transparent" target="_blank"><img src='images/throne.png' /></Button> : ""}
          {paypal != "" ? <Button as="a" href={paypal} variant="transparent" target="_blank"><img src='images/paypal.png' /></Button> : ""}
          {cashapp != "" ? <Button as="a" href={cashapp} variant="transparent" target="_blank"><img src='images/cashapp.png' /></Button> : ""}

        </div></div>}
    </div>
  );
}

export default UserPinnedSocialApps;